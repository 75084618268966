<template>
  <v-container>
    <div class="text-center">
      <div class="icon">
        <svg height="200" width="200" xmlns="http://www.w3.org/2000/svg">
          <image
            href="https://a-prokat.storage.yandexcloud.net/1/fc581057-c08d-45fe-ac54-d96f16b5b018/404-2.png"
            height="200"
            width="200"
          />
        </svg>
      </div>
      <div class="text__head">
        <h2>Уважаемый клиент!</h2>
        <h3>Время на оплату истекло</h3>
        <h5>
          Вы будуте перенаправлены на главную через
          {{ timeToRedirect() }} секунд
        </h5>
      </div>
      <div>
        <v-btn outlined large @click="goMain">На главную</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Оплата',
    };
  },
  data: () => ({
    previousRoute: null,
    timeTo: 5,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from.path;
    });
  },
  methods: {
    goMain() {
      this.$router.push('/');
    },
    timeToRedirect() {
      if (this.timeTo > 0) {
        setTimeout(() => {
          this.timeTo -= 1;
        }, 1000);
      }
      return this.timeTo;
    },
  },
  mounted() {
    setTimeout(() => {
      this.$router.push('/');
    }, 5000);
  },
};
</script>

<style lang="scss" scoped>
.icon {
  margin: 40px auto 30px;
  transition-property: top;
  transition-duration: 2s;
  svg {
    margin-left: auto;
    filter: drop-shadow(0px 3px 5px black) drop-shadow(0px 18px 30px #1565c0);
  }
}
.link {
  font-size: 0.9em;
}
.text__head {
  margin: 50px auto 50px;
}
.trasnparent {
  opacity: 0.3;
  position: absolute;
  bottom: 5px;
  width: 320px;
  left: calc(50% - 160px);
}
.text-center {
  transition-property: margin;
  transition-duration: 1s;
}

@media (min-width: 900px) {
  .text-center {
    margin-top: 125px;
    transition-property: margin;
    transition-duration: 1s;
  }
}
@media (max-height: 610px) {
  .trasnparent {
    display: none;
  }
}
</style>
